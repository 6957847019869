<template>
  <div class="ocspQuery">
    <img class="bg-img" src="@/assets/image/home/ocsp-bg.png" alt="ocsp在线查询" srcset="">
    <div class="form-box">
      <el-form ref="form" label-position="left" :model="formData" hide-required-asterisk :rules="rules" label-width="100px">
        <el-form-item label="证书序列号：" prop="certSn">
          <el-input v-model="formData.certSn" placeholder="请输入您的证书序列号" maxlength="24"></el-input>
        </el-form-item>
        <el-form-item v-if="dedicated" label="类型：" prop="temporary">
          <el-select v-model="formData.isSm2" placeholder="请选择需要查询的证书类型">
            <el-option label="RSA" :value="false"></el-option>
            <el-option label="SM2" :value="true"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="koalQryByCertSn">点击查询</el-button>
    </div>
    <div class="bottom">
      <div>©山东豸信认证服务有限公司2014-2024版权所有</div>
      <div><a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">鲁ICP备15043318号</a> &nbsp;&nbsp; <a target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001700" rel="nofollow">鲁公网安备 37011202001700号</a> </div>
    </div>
    <transition name="el-fade-in-linear">
      <div v-show="resultShow" class="result-box">
        <div class="result">
          <div class="title">查询结果</div>
          <div class="hint" v-if="result===0">
            <div class="tt">未查询到相关数字证书信息</div>
            <div>
              可能原因：<br />1. 数字证书序列号输入错误，请检查是否输入有误。<br />2. 该数字证书不是由豸信CA颁发，请确认证书的颁发机构是否为豸信CA。
            </div>
          </div>
          <div v-else class="info">
            <div>
              <div class="label">颁发机构</div>
              <div>豸信CA</div>
            </div>
            <div>
              <div class="label">证书状态</div>
              <div>
                <span :class="{cancelled:result===-1}" class="state ">{{result===1?'正常':'已注销'}}</span>
              </div>
            </div>
          </div>

          <el-button type="primary" @click="resultShow=false">好的</el-button>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  data () {
    return {
      formData: {
        certSn: '',
        isSm2: true,
        raType: 3
      },
      dedicated: true,
      resultShow: false,
      result: 0,
      rules: {
        certSn: [
          {
            required: true,
            message: '请输入要查询的证书序列号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    let routerName = this.$route.name
    this.dedicated = routerName === 'dedicatedInquiry'
    this.formData.raType = routerName === 'dedicatedInquiry' ? 2 : 3
  },
  methods: {
    koalQryByCertSn () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$tencentCaptcha(val => {
            this.$api
              .koalQryByCertSn({ ...val, ...this.formData })
              .then(res => {
                if (res.code === '0') {
                  this.resultShow = true
                  this.result = res.data
                }
              })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ocspQuery {
  position: relative;
  height: 100vh;
  background: #f5f8fb;
  width: 100%;
  overflow: hidden;
  min-height: 800px;
  * {
    box-sizing: border-box;
  }
  .bg-img {
    position: absolute;
    height: 558px;
    min-width: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .form-box {
    width: 872px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    top: 299px;
    padding: 90px 141px;
    text-align: center;
    margin: 0 auto;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    .el-button {
      margin-top: 60px;
      width: 252px;
      height: 54px;
      background: $primaryColor;
      border-radius: 32px;
      font-size: 16px;
    }
  }
  .result-box {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    .result {
      padding: 32px;
      width: 578px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      text-align: center;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #303b50;
        padding-top: 8px;
        line-height: 21px;
        margin-bottom: 32px;
      }
      .info {
        border: 1px solid #e6e8ea;
        line-height: 76px;
        margin-bottom: 40px;
        text-align: left;
        & > div {
          display: flex;
          &:first-child {
            border-bottom: 1px solid #e6e8ea;
          }
          & > div {
            padding: 0 38px;
            &:first-child {
              border-right: 1px solid #e6e8ea;
              padding: 0 28px;
              width: 150px;
            }
          }
        }
        .state {
          display: inline-block;
          line-height: 32px;
          padding: 0 28px;
          text-align: center;
          background: #eff9eb;
          border-radius: 4px;
          border: 1px solid #d4f0c8;
          color: #52c11b;
          &.cancelled {
            background: #fff7f7;
            border: 1px solid #ffd2ce;
            color: #ed5448;
          }
        }
      }
      .hint {
        text-align: left;
        padding-top: 8px;
        color: #333333;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 42px;
        margin-bottom: 28px;
        .tt {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 12px;
        }
      }
      .el-button {
        width: 130px;
        border-radius: 4px;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 20px;
    font-size: 14px;
    color: #5c6269;
    line-height: 27px;
    text-align: center;
    width: 100%;
    a {
      color: #5c6269;
    }
  }
}
</style>
